<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #filters>
        <div class="search-filters">
          <SearchFilter :isFilterSearch="false">
            <li>
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Invoice Type") }}
                </label>
                <multiselect
                  v-model="form.invoiceType"
                  :options="['invoice-correction', 'invoice', 'invoice-storno']"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Status") }}
                </label>
                <multiselect
                  v-model="form.status"
                  :options="[
                    'draft',
                    'approved',
                    'sent',
                    'warning level 1',
                    'warning level 2',
                    'warning level 3',
                    'paid',
                  ]"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Customer") }}
                </label>
                <MultiSelectInput
                  v-model="form.company"
                  :options="customers"
                  label="companyName"
                  trackBy="id"
                  moduleName="customers"
                  :multiple="false"
                  ><template #option="{ props }">
                    <div>
                      <p>
                        {{ props.option.companyNumber }} -
                        {{ props.option.companyName }}
                      </p>
                    </div>
                  </template>
                </MultiSelectInput>
              </div>
            </li>
          </SearchFilter>
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="form.search"
            />
            <button class="" type="button" @click="reset">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
      <template #buttons>
        <button
          v-if="$can(`${$route.meta.permission}.export-invoice-plan`)"
          @click="downloadInvoicePlan()"
          class="btn btn-primary"
        >
          <span>{{ $t("Export Invoice Plan") }}</span>
        </button>
        <button
          v-if="$can(`${$route.meta.permission}.export-csv`)"
          @click="downloadCSV()"
          class="btn btn-primary"
        >
          <span>{{ $t("Export CSV") }}</span>
        </button>
        <!-- <button
          v-if="$can(`${$route.meta.permission}.export-csv-latest`)"
          @click="downloadLatestCSV()"
          class="btn btn-primary"
        >
          <span>{{ $t("Export Latest CSV") }}</span>
        </button> -->
      </template>
    </PageHeader>
    <div class="custom-search d-flex justify-content-between mb-1">
      <div class="d-flex align-items-center">
        <div class="checkbox-group mr-2">
          <input
            type="checkbox"
            v-model="selectAll"
            @change="toggleSelectAll"
            class="checkbox-input"
            id="check"
          />
          <label for="check" class="checkbox-label">{{
            $t("Select All")
          }}</label>
        </div>
        <!-- <button  class="btn btn-primary mr-1">
          <span>{{ $t("Send All Selected") }}</span>
        </button> -->
        <button @click="generateSelected()" class="btn btn-primary">
          <span>{{ $t("Generate Selected") }}</span>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <b-form-group class="m-0">
          <div class="d-flex align-items-center">
            <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
            <b-form-select
              v-model="pageLength"
              :options="['25', '50', '100']"
              class="ml-1"
              @input="(value) => onPerPageChange({ pageLength: value })"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->

            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                v-if="props.row.status == 'approved'"
                @click="toggleModal(props.row)"
                class="cursor-pointer"
                :title="$t('Send Mail')"
              >
                <feather-icon size="16" icon="MailIcon" />
              </div>
              <div
                v-if="props.row.status == 'sent'"
                @click="showSentModal(props.row)"
                class="cursor-pointer"
                :title="$t('Send Mail')"
              >
                <feather-icon size="16" icon="MailIcon" />
              </div>
              <div
                @click="generate(props.row)"
                class="cursor-pointer"
                :title="$t('Generate Document')"
              >
                <feather-icon size="16" icon="FileTextIcon" />
              </div>
              <div
                class="cursor-pointer"
                :title="$t('Show')"
                @click="$router.push(`/invoices/${props.row.id}/show`)"
                v-if="
                  $can(`${$route.meta.permission}.list`) ||
                  $can(`${$route.meta.permission}.show-all`)
                "
              >
                <feather-icon size="16" icon="EyeIcon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.edit`)"
                class="cursor-pointer"
                :title="$t('Edit')"
                @click="$router.push(`/invoices/${props.row.id}/edit`)"
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="
                  props.row.status == 'draft' &&
                  $can(`${$route.meta.permission}.delete`)
                "
                class="cursor-pointer"
                :title="$t('Trash')"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>

            <span v-else-if="props.column.field === 'totalAmount_numeric'">
              {{
                $formatter(
                  props.row.totalAmount,
                  $i18n.locale,
                  "EUR",
                  false,
                  2,
                  2
                )
              }}
            </span>
            <span v-else-if="props.column.field === 'dueDate'">
              <p class="">
                {{ $dateFormatter(props.row.dueDate, $i18n.locale) }}
              </p>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <select
                class="form-control"
                :key="props.row"
                @change="updateInvoice(props.row, $event.target.value)"
                :disabled="!$can(`${$route.meta.permission}.edit`)"
              >
                <option
                  v-for="status in filterStatusOptions(props.row.status)"
                  :key="status"
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </span>
            <span
              v-else-if="props.column.field === 'netto_numeric'"
              style="white-space: nowrap"
            >
              {{
                $formatter(props.row.netto, $i18n.locale, "EUR", false, 2, 2)
              }}
            </span>

            <span v-else-if="props.column.field === 'taxAmount_numeric'">
              {{
                $formatter(
                  props.row.taxAmount,
                  $i18n.locale,
                  "EUR",
                  false,
                  2,
                  2
                )
              }}
            </span>
            <span v-else-if="props.column.field === 'Company.companyName'">
              {{ props.row.companyName }}
            </span>
            <span v-else-if="props.column.field === 'checkbox'">
              <div class="checkbox-group">
                <input
                  :id="'row-' + props.row.id"
                  v-model="selectedRows"
                  :value="props.row.id"
                  type="checkbox"
                  class="checkbox-input"
                />
                <label
                  :for="'row-' + props.row.id"
                  class="checkbox-label m-0"
                ></label>
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <b-row>
        <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <div class="checkbox-group">
                  <input
                    :id="'row-' + row.id"
                    v-model="selectedRows"
                    :value="row.id"
                    type="checkbox"
                    class="checkbox-input"
                  />
                  <label
                    :for="'row-' + row.id"
                    class="checkbox-label m-0"
                  ></label>
                </div>
                <h5>{{ row.invoiceNumber }}</h5>
              </div>
              <div class="d-flex gap-3">
                <!-- Send Mail -->
                <div
                  v-if="row.status == 'approved'"
                  @click="toggleModal(row)"
                  class="cursor-pointer"
                  :title="$t('Send Mail')"
                >
                  <feather-icon size="18" icon="MailIcon" />
                </div>

                <div
                  v-if="row.status == 'sent'"
                  @click="showSentModal(row)"
                  class="cursor-pointer"
                  :title="$t('Mail Sent')"
                >
                  <feather-icon size="18" icon="MailIcon" />
                </div>

                <!-- Generate Document -->
                <div
                  @click="generate(row)"
                  class="cursor-pointer"
                  :title="$t('Generate Document')"
                >
                  <feather-icon size="18" icon="FileTextIcon" />
                </div>

                <!-- View Invoice -->
                <div
                  class="cursor-pointer"
                  :title="$t('Show')"
                  @click="$router.push(`/invoices/${row.id}/show`)"
                >
                  <feather-icon size="18" icon="EyeIcon" />
                </div>

                <!-- Edit Invoice -->
                <div
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  class="cursor-pointer"
                  :title="$t('Edit')"
                  @click="$router.push(`/invoices/${row.id}/edit`)"
                >
                  <feather-icon size="18" icon="Edit2Icon" />
                </div>

                <!-- Delete Draft Invoice -->
                <div
                  v-if="
                    row.status == 'draft' &&
                    $can(`${$route.meta.permission}.delete`)
                  "
                  class="cursor-pointer"
                  :title="$t('Trash')"
                  @click="destroy(row.id)"
                >
                  <feather-icon size="18" icon="TrashIcon" />
                </div>
              </div>
            </div>

            <div class="card-body pt-0">
              <ul class="card-list">
                <!-- <li>
                  <div class="left">
                    <strong>{{ $t("Invoice Number") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.invoiceNumber }}</span>
                  </div>
                </li> -->
                <li>
                  <div class="left">
                    <strong>{{ $t("Receiver") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.companyName }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Invoice Type") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.invoiceType }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Due Date") }}: </strong>
                  </div>
                  <div class="right">
                    <span>
                      {{ $dateFormatter(row.dueDate, $i18n.locale) }}</span
                    >
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Status") }}: </strong>
                  </div>
                  <div class="right">
                    <select
                      class="form-control w-100"
                      @change="updateInvoice(row, $event.target.value)"
                    >
                      <option
                        v-for="status in filterStatusOptions(row.status)"
                        :key="status"
                        :value="status"
                      >
                        {{ status }}
                      </option>
                    </select>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Netto") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $formatter(row.netto, $i18n.locale, "EUR", false, 2, 2)
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Tax Amount") }}: </strong>
                  </div>
                  <div class="right">
                    {{
                      $formatter(
                        row.taxAmount,
                        $i18n.locale,
                        "EUR",
                        false,
                        2,
                        2
                      )
                    }}
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Total Amount") }}: </strong>
                  </div>
                  <div class="right">
                    {{
                      $formatter(
                        row.totalAmount,
                        $i18n.locale,
                        "EUR",
                        false,
                        2,
                        2
                      )
                    }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-center mt-2">
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="c-modal" v-show="showModal">
      <div class="c-modal-content">
        <div class="c-modal-header">
          <h3>{{ $t("Send Mail") }}</h3>
          <div class="x-icon">
            <feather-icon size="16" icon="XIcon" @click="closeModal" />
          </div>
        </div>
        <div class="c-modal-body">
          <SendMailModal
            :to="invoiceEmailAddress"
            :invoiceTemplate="invoiceTemplate"
            :jsonData="selectedMailInvoice"
            v-if="showModal"
          />
        </div>
      </div>
    </div>
    <b-modal
      id="edit-tag-modal"
      v-model="showPromptModal"
      title="Do you really want to resend the invoice?"
      centered
      size="md"
      hide-footer
    >
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-button
          class="mr-2"
          @click="showPromptModal = false"
          variant="primary"
          >{{ $t("Cancel") }}</b-button
        >
        <b-button @click="toggleModal(selectedInvoice)" variant="primary">{{
          $t("Resend Mail")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import SendMailModal from "../components/SendMailModal.vue";
import NotificationService from "../../services/notification.service";
import Multiselect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import { props } from "vue-prism-component";
import { debounce } from "@/utils/debounce";
import MonacoEditor from "monaco-editor-vue";
export default {
  components: {
    PageHeader,
    Multiselect,
    SearchFilter,
    MultiSelectInput,
    MonacoEditor,
    SendMailModal,
  },
  computed: {
    ...mapGetters("customers", ["customers"]),
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Invoice"),
          icon: "PlusIcon",
          path: "/invoices/create",
          permission: `${this.$route.meta.permission}.create`,
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Invoices"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t(""),
          field: "checkbox",
          sortable: false,
        },
        {
          label: this.$t("Invoice Number"),
          field: "invoiceNumber",
        },
        {
          label: this.$t("Receiver"),
          field: "Company.companyName",
        },
        {
          label: this.$t("Invoice Type"),
          field: "invoiceType",
        },
        {
          label: this.$t("Due Date"),
          field: "dueDate",
        },
        {
          label: this.$t("Status"),
          field: "status",
        },
        {
          label: this.$t("Netto"),
          field: "netto_numeric",
        },
        {
          label: this.$t("Tax Amount"),
          field: "taxAmount_numeric",
        },
        {
          label: this.$t("Total Amount"),
          field: "totalAmount_numeric",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    selectedRows(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    form: {
      handler() {
        this.debouncedFetch();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        //Monaco Editor Options
      },
      selectedMailInvoice: {},
      showModal: false,
      totalRecords: 0,
      pageLength: 25,
      selectAll: false,
      selectedRows: [],
      page: 1,
      rows: [],
      sortBy: "createdAt",
      sortOrder: "desc",
      form: {
        invoiceType: "",
        status: "",
        company: "",
        type: "",
        search: "",
        status: "",
      },

      modelData: {},
      mailData: {},
      selectedInvoice: {},
      reminderLevels: [],
      invoiceTemplate: {},
      invoiceEmailAddress: "",
      showPromptModal: false,
    };
  },

  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    this.loadItems();
    const response2 = await this.$store.dispatch(
      "mailTemplates/mailTemplateAssignmentList"
    );
    this.mailData = response2?.data?.data ?? {};
    const response = await this.$store.dispatch(
      "invoices/documentAssignmentList"
    );
    this.modelData = response?.data?.data ?? {};
    let reminders = await this.$store.dispatch("invoiceReminderLevel/list");
    this.reminderLevels = reminders?.data?.data;
    await this.$store.dispatch("customers/list");
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    filterStatusOptions(status) {
      const allOptions = [
        "draft",
        "approved",
        "sent",
        "warning level 1",
        "warning level 2",
        "warning level 3",
        "paid",
      ];

      const statusIndex = allOptions.indexOf(status);

      if (statusIndex !== -1) {
        // Update statusOptions to remove all previous statuses
        return allOptions.slice(statusIndex);
      } else {
        // If the status is not found, keep all options
        return allOptions;
      }
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    toggleModal(invoice) {
      this.showPromptModal = false;

      if (invoice.invoiceType == "invoice") {
        this.invoiceTemplate = this.mailData.find(
          (item) => item.module === "invoiceTemplate"
        );
      }

      if (invoice.invoiceType == "invoice-correction") {
        this.invoiceTemplate = this.mailData.find(
          (item) => item.module === "invoiceCorrectionTemplate"
        );
      }

      if (invoice.invoiceType == "invoice-storno") {
        this.invoiceTemplate = this.mailData.find(
          (item) => item.module === "invoiceStornoTemplate"
        );
      }

      this.invoiceEmailAddress = invoice.company?.invoiceEmailAddress ?? "";
      this.selectedMailInvoice = JSON.stringify(invoice);
      this.showModal = true;
    },
    showSentModal(invoice) {
      this.selectedInvoice = invoice;

      this.showPromptModal = true;
    },
    async downloadCSV() {
      try {
        await this.$store.dispatch("invoices/download");
      } catch (e) {}
    },
    async downloadLatestCSV() {
      try {
        await this.$store.dispatch("invoices/downloadLatestCSV");
      } catch (e) {}
    },
    async downloadInvoicePlan() {
      try {
        await this.$store.dispatch("invoices/downloadInvoicePlan");
      } catch (e) {}
    },
    async updateInvoice(invoice, status) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to change the status to ") + status + "?",
        subText: this.$t("Yes change it!"),
      });

      if (confirmed) {
        let payload = {};
        let storeDispatch = "invoices/update";
        if (invoice.status !== "draft") {
          payload = { status: status };
          storeDispatch = "invoices/updateStatus";
        } else {
          payload = {
            ...invoice,
            senderId: invoice.sender?.id ?? null,
            status: status,
          };
        }
        await this.$store
          .dispatch(storeDispatch, {
            id: invoice.id,
            data: payload,
          })
          .finally(() => {
            this.loadItems();
          });
      } else {
        this.loadItems();
      }
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    closeModal() {
      this.showModal = false;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedRows = this.rows.map((row) => row.id);
      } else {
        this.selectedRows = [];
      }
    },
    async generate(invoice) {
      let templateId;
      if (invoice.status.startsWith("warning level")) {
        const matchedLevel = this.reminderLevels.find(
          (level) => level.levelName === invoice.status
        );

        templateId = matchedLevel ? matchedLevel.documentTemplateId : "";
      } else {
        // Handle other cases based on the form status
        switch (invoice.status) {
          case "invoice":
            templateId = this.modelData?.invoiceTemplateId ?? "";
            break;
          case "invoice-correction":
            templateId = this.modelData?.invoiceCorrectionTemplateId ?? "";
            break;
          case "invoice-storno":
            templateId = this.modelData?.invoiceStornoTemplateId ?? "";
            break;
          default:
            templateId = this.modelData?.invoiceTemplateId ?? ""; // Fallback to invoiceTemplateId
        }
      }
      if (templateId != "") {
        // Safeguard to ensure convertDate returns a valid Date object
        const parseDate = (date) => {
          const parsedDate = this.convertDate(date);
          return parsedDate instanceof Date && !isNaN(parsedDate)
            ? parsedDate
            : new Date(date);
        };

        const invoiceDate = invoice?.invoiceDate
          ? parseDate(invoice.invoiceDate)
          : this.formatDateLite(new Date());

        const startDate = invoice?.startDate
          ? parseDate(invoice.startDate)
          : null;

        const endDate = invoice?.endDate ? parseDate(invoice.endDate) : null;

        const dueDate = invoice?.dueDate ? parseDate(invoice.dueDate) : null;

        const draftStatusChangeDate = invoice?.invoiceDate
          ? parseDate(invoice.invoiceDate)
          : null;

        // Create the payload
        const payload = {
          ...invoice,
          customers: invoice.company,
          invoiceStatus: invoice.status,
          totalAmount: invoice.totalAmount,
          totalTaxAmount: invoice.taxAmount,
          totalAmountWithoutTax: invoice.netto,
          invoiceDate: invoiceDate.toLocaleDateString(),
          startDate: startDate ? startDate.toLocaleDateString() : null,
          endDate: endDate ? endDate.toLocaleDateString() : null,
          dueDate: dueDate ? dueDate.toLocaleDateString() : null,
          draftStatusChangeDate: draftStatusChangeDate
            ? draftStatusChangeDate.toLocaleDateString()
            : "",
          id: templateId,
          output: "pdf",
          updatedTime: Date.now(),
        };

        // Generate the filename
        const filename =
          "invoice-" +
          (invoice.invoiceNumber == null ? "draft" : invoice.invoiceNumber) +
          `.${"pdf"}`;

        // Dispatch the action
        this.$store.commit("showLoader", true);
        const response = await this.$store.dispatch(
          "documentService/processTemplate",
          {
            data: payload,
            filename: filename,
            documentType: "pdf",
          }
        ).finally(() => {
          this.$store.commit("showLoader", false);
        });

        // If response is Blob, convert to Base64
        if (response instanceof Blob) {
          await this.convertBlobToBase64(response);
        }
      } else {
        NotificationService.showError("Please assign document template first");
      }
    },
    async generateSelected() {
      // Filter rows where invoice.id exists in selectedRows array
      const selectedInvoices = this.rows.filter((invoice) =>
        this.selectedRows.includes(invoice.id)
      );
      for (let i = 0; i < selectedInvoices.length; i++) {
        try {
          await this.generate(selectedInvoices[i]); // Try generating
        } catch (error) {
          console.error(
            `Error processing invoice ${selectedInvoices[i].id}:`,
            error
          );
        }
      }
    },

    convertDate(dateString) {
      // Convert the date string into a Date object
      const date = new Date(dateString);

      // Convert the date to the desired format (Pakistan Standard Time)
      // Forcing timezone shift by setting the time to GMT+0500
      date.setTime(date.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours

      // Return the formatted string
      return date.toString();
    },
    formatDateLite(date, showTime = false) {
      try {
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${date.getFullYear()}-${m < 10 ? "0" : ""}${m}-${
          d < 10 ? "0" : ""
        }${d}${showTime ? " " + date.toLocaleTimeString() : ""}`;
      } catch (e) {
        return date;
      }
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("invoices/list", {
          page: this.page,
          ...this.form,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          search: this.form.search,
          company: this.form.company?.id ?? "",
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("invoices/destroy", id).finally(() => {
          this.loadItems();
        });
      }
    },
    reset() {
      this.form = {
        invoiceType: "",
        status: "",
        search: "",
        company: "",
      };
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
